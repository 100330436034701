// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lvSzrR6uXOH_6T47WOnY {
  object-fit: cover;
  height: 18em;
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/routes/About.scss"],"names":[],"mappings":"AAMA;EACC,iBAAA;EACA,YAAA;EACA,WAAA;AALD","sourcesContent":["@import \"css/config.scss\";\n\n// The number of lines the picture should take up. This should match the same\n// number of lines that the column next to the picture is, at full width.\n$lines: 8;\n\n.face {\n\tobject-fit: cover;\n\theight: $body-line-height * $lines;\n\twidth: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"face": `lvSzrR6uXOH_6T47WOnY`
};
export default ___CSS_LOADER_EXPORT___;
