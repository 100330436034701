// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hwlimuv2lMY1EVODJ4ga {
  position: relative;
  height: 17.5em;
}

.qS4FQsdmi_vXbQOkq8_D {
  transition: transform 0.2s;
}

.hwlimuv2lMY1EVODJ4ga:hover .hWIKhaNCk2sUqRyb39hb {
  outline: 4px solid #769add;
  transform: translate(0, -0.625em);
}

.hwlimuv2lMY1EVODJ4ga:hover .Ogrha0BF_s4E924fhyF4 {
  outline: 4px solid #769add;
  transform: translate(0.625em, 0);
}

.UvedlkfiepSAvw3yptTQ {
  color: #DAD2D5;
}

.Q6jqHbWFZpxboL9he3yj {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 2px dashed #DAD2D5;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectTile.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAMA;EACC,kBAAA;EACA,cAAA;AALD;;AAQA;EACC,0BAAA;AALD;;AAQA;EACC,0BAAA;EACA,iCAAA;AALD;;AAOA;EACC,0BAAA;EACA,gCAAA;AAJD;;AAOA;EACC,cCxBM;ADoBP;;AAOA;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;EACA,0BAAA;EACA,WAAA;AAJD","sourcesContent":["@import \"../css/config.scss\";\n\n// Dotted border effect inspired by:\n// https://codepen.io/markmead/pen/MqmOVB\n\n// Holds the border and tile contents\n.container {\n\tposition: relative;\n\theight: $body-font-size * 14;\n}\n\n.image {\n\ttransition: transform 0.2s;\n}\n\n.container:hover .hoverUp {\n\toutline: 4px solid $link-hover;\n\ttransform: translate(0, -($body-font-size / 2));\n}\n.container:hover .hoverRight {\n\toutline: 4px solid $link-hover;\n\ttransform: translate($body-font-size / 2, 0);\n}\n\n.link {\n\tcolor: $text;\n}\n\n.border {\n\tposition: absolute;\n\twidth: 100%;\n\theight: 100%;\n\tleft: 0;\n\ttop: 0;\n\tborder: 2px dashed $text;\n\tz-index: -1;\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hwlimuv2lMY1EVODJ4ga`,
	"image": `qS4FQsdmi_vXbQOkq8_D`,
	"hoverUp": `hWIKhaNCk2sUqRyb39hb`,
	"hoverRight": `Ogrha0BF_s4E924fhyF4`,
	"link": `UvedlkfiepSAvw3yptTQ`,
	"border": `Q6jqHbWFZpxboL9he3yj`
};
export default ___CSS_LOADER_EXPORT___;
