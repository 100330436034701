// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U8tFA9tpjVYBDEOeAnhN li {
  display: inline-block;
  padding-right: 1.25em;
}
.U8tFA9tpjVYBDEOeAnhN li:last-of-type {
  padding-right: 0;
}

.GwKmqVGQKZKx9L4sZJvL {
  text-align: center;
}

.BVB22IHwP1SMG1MXwtXL {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/HorizontalList.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAEA;EACC,qBAAA;EACA,qBCQgB;ADTjB;AAGC;EACC,gBAAA;AADF;;AAKA;EACC,kBAAA;AAFD;;AAKA;EACC,iBAAA;AAFD","sourcesContent":["@import \"../css/config.scss\";\n\n.list li {\n\tdisplay: inline-block;\n\tpadding-right: $body-font-size;\n\n\t&:last-of-type {\n\t\tpadding-right: 0;\n\t}\n}\n\n.isCentered {\n\ttext-align: center;\n}\n\n.isRightAligned {\n\ttext-align: right;\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `U8tFA9tpjVYBDEOeAnhN`,
	"isCentered": `GwKmqVGQKZKx9L4sZJvL`,
	"isRightAligned": `BVB22IHwP1SMG1MXwtXL`
};
export default ___CSS_LOADER_EXPORT___;
