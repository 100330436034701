// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IXPv0K0JuT3oM69uYZik {
  margin-bottom: 0.3125em;
}

.EGaiz0jDazECj185Khl_ {
  font-style: italic;
  color: #94878C;
}

.JvY9XwqBtkt2tYhefZKl:hover {
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/routes/ProjectDetail.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAEA;EACC,uBAAA;AADD;;AAIA;EACC,kBAAA;EACA,cCNY;ADKb;;AAKC;EACC,SAAA;AAFF","sourcesContent":["@import \"css/config.scss\";\n\n.title {\n\tmargin-bottom: $body-font-size / 4;\n}\n\n.subtitle {\n\tfont-style: italic;\n\tcolor: $text-faded;\n}\n\n.projectLink {\n\t&:hover {\n\t\tborder: 0;\n\t}\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `IXPv0K0JuT3oM69uYZik`,
	"subtitle": `EGaiz0jDazECj185Khl_`,
	"projectLink": `JvY9XwqBtkt2tYhefZKl`
};
export default ___CSS_LOADER_EXPORT___;
