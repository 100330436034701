// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NARH2ipgIb19001zSdxF {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-block-end: 2.25em;
}

.s6uaZKkakWDPM_A_X5uM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/YouTubeEmbed.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAEA;EACC,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,wBCOkB;ADRnB;;AAIA;EACC,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AADD","sourcesContent":["@import \"css/config.scss\";\n\n.videoContainer {\n\tposition: relative;\n\twidth: 100%;\n\tpadding-top: 56.25%; // 100/56.25 = 560/315 = 1.778\n\tmargin-block-end: $body-line-height;\n}\n\n.video {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoContainer": `NARH2ipgIb19001zSdxF`,
	"video": `s6uaZKkakWDPM_A_X5uM`
};
export default ___CSS_LOADER_EXPORT___;
