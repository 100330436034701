// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.REGL8tTj2FGUwIdHctsw .slick-dots {
  position: static;
  margin-block-end: 1.25em;
  bottom: 0;
  font-size: 1.25em;
  line-height: 1.25em;
}
.REGL8tTj2FGUwIdHctsw .slick-dots .slick-active button:before {
  color: #DAD2D5;
}
.REGL8tTj2FGUwIdHctsw .slick-dots button:before {
  color: #DAD2D5;
}

.yXUkHlLuvComSG8mEG4q {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageSlider.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAIE;EACC,gBAAA;EACA,wBCMc;EDLd,SAAA;EACA,iBCIc;EDHd,mBCGc;ADNjB;AAMI;EACC,cCZE;ADQP;AAQG;EACC,cCjBG;ADWP;;AAYA;EACC,gBAAA;AATD","sourcesContent":["@import \"css/config.scss\";\n\n.slider {\n\t:global {\n\t\t.slick-dots {\n\t\t\tposition: static;\n\t\t\tmargin-block-end: $body-font-size;\n\t\t\tbottom: 0;\n\t\t\tfont-size: $body-font-size;\n\t\t\tline-height: $body-font-size;\n\n\t\t\t.slick-active {\n\t\t\t\tbutton:before {\n\t\t\t\t\tcolor: $text;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tbutton:before {\n\t\t\t\tcolor: $text;\n\t\t\t}\n\t\t}\n\t}\n}\n\n.figure {\n\tmargin-bottom: 0;\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `REGL8tTj2FGUwIdHctsw`,
	"figure": `yXUkHlLuvComSG8mEG4q`
};
export default ___CSS_LOADER_EXPORT___;
