// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WiespVaoab5_3cpL6Qy0 {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 2.25em;
}
.WiespVaoab5_3cpL6Qy0 a {
  display: block;
  margin: 0;
  padding: 0.3125em;
}
.WiespVaoab5_3cpL6Qy0 a:hover {
  border: 0;
}

.jaBI9ozJ03DRzmHrEzE4 {
  color: #6093F3;
  border: 2px solid #6093F3;
}
.jaBI9ozJ03DRzmHrEzE4:hover {
  border-color: #769add;
}`, "",{"version":3,"sources":["webpack://./src/components/Button.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAEA;EACC,qBAAA;EACA,WAAA;EACA,kBAAA;EAEA,qBCMkB;ADRnB;AAIC;EACC,cAAA;EACA,SAAA;EACA,iBAAA;AAFF;AAIE;EAEC,SAAA;AAHH;;AAQA;EACC,cCjBO;EDkBP,yBAAA;AALD;AAOC;EACC,qBCPW;ADEb","sourcesContent":["@import \"css/config\";\n\n.button {\n\tdisplay: inline-block;\n\twidth: 100%;\n\ttext-align: center;\n\n\tmargin-bottom: $body-line-height;\n\n\ta {\n\t\tdisplay: block;\n\t\tmargin: 0;\n\t\tpadding: $body-font-size / 4;\n\n\t\t&:hover {\n\t\t\t// Override default link styles\n\t\t\tborder: 0;\n\t\t}\n\t}\n}\n\n.outline {\n\tcolor: $link;\n\tborder: 2px solid $link;\n\n\t&:hover {\n\t\tborder-color: $link-hover;\n\t}\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `WiespVaoab5_3cpL6Qy0`,
	"outline": `jaBI9ozJ03DRzmHrEzE4`
};
export default ___CSS_LOADER_EXPORT___;
