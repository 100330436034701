// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z85_CdMKcY6A5jwLu1Gi {
  font-size: 0.8125em;
}

.xcGWT1wCIUNIpCFyNfl_ {
  font-size: 1.1875em;
}

.O98iqP_6vJbiey6SjCn0 {
  line-height: 1.8em;
}`, "",{"version":3,"sources":["webpack://./src/css/generic.scss"],"names":[],"mappings":"AAEA;EACC,mBAAA;AADD;;AAIA;EACC,mBAAA;AADD;;AAIA;EACC,kBAAA;AADD","sourcesContent":["@import \"./config.scss\";\n\n.finePrint {\n\tfont-size: $body-font-size * 0.65;\n}\n\n.bigText {\n\tfont-size: $body-font-size * 0.95;\n}\n\n.tight {\n\tline-height: $body-line-height * 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finePrint": `z85_CdMKcY6A5jwLu1Gi`,
	"bigText": `xcGWT1wCIUNIpCFyNfl_`,
	"tight": `O98iqP_6vJbiey6SjCn0`
};
export default ___CSS_LOADER_EXPORT___;
