export default [
	require('./aquaman').default,
	require('./wild-revolvers').default,
	require('./rhinebeck').default,
	require('./mirage-isle').default,
	// require('./class-lua').default,
	// require('./repo-import').default,
	require('./echo-ridge').default,
	// require('./elixir').default,
	require('./fireflies').default,
	// require('./snowy-mountain').default
]
