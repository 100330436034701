// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._SupnsjRstZfwPUISnr3 {
  height: 2.25em;
  display: inline-block;
}
._SupnsjRstZfwPUISnr3:hover .GxdXWsxn7PWCtKrogKcv {
  transform: rotate(-10deg);
}

.GxdXWsxn7PWCtKrogKcv {
  margin-bottom: 0;
  height: 100%;
  transition: all 0.2s;
  transition-timing-function: easeOutElastic;
}

.BRbS5Ztzuldo8GGfoozQ:hover {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAEA;EACC,cCUkB;EDTlB,qBAAA;AADD;AAIE;EACC,yBAAA;AAFH;;AAOA;EACC,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,0CAAA;AAJD;;AAQC;EACC,YAAA;AALF","sourcesContent":["@import \"css/config.scss\";\n\n.base {\n\theight: $body-line-height;\n\tdisplay: inline-block;\n\n\t&:hover {\n\t\t.image {\n\t\t\ttransform: rotate(-10deg);\n\t\t}\n\t}\n}\n\n.image {\n\tmargin-bottom: 0;\n\theight: 100%;\n\ttransition: all 0.2s;\n\ttransition-timing-function: easeOutElastic;\n}\n\n.link {\n\t&:hover {\n\t\tborder: none;\n\t}\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `_SupnsjRstZfwPUISnr3`,
	"image": `GxdXWsxn7PWCtKrogKcv`,
	"link": `BRbS5Ztzuldo8GGfoozQ`
};
export default ___CSS_LOADER_EXPORT___;
