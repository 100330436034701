// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jp4gx4kOu7O8brvfGkkm, .Fw4rVY2ZMhH2dOz27QLM, .cFntQLXtO8JagkN4pYO0 {
  width: 64px;
  height: 64px;
}

.cFntQLXtO8JagkN4pYO0 {
  transform: rotate(190deg);
}

.Fw4rVY2ZMhH2dOz27QLM {
  transform: skew(10deg, 50deg);
}

.jp4gx4kOu7O8brvfGkkm {
  transform: scale(2, 2) skew(-20deg, -10deg) rotate(-20deg);
  perspective: 20px;
}`, "",{"version":3,"sources":["webpack://./src/routes/Brand.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;AACD;;AAEA;EAEC,yBAAA;AAAD;;AAGA;EAEC,6BAAA;AADD;;AAIA;EAEC,0DAAA;EACA,iBAAA;AAFD","sourcesContent":["%boy {\n\twidth: 64px;\n\theight: 64px;\n}\n\n.rotato {\n\t@extend %boy;\n\ttransform: rotate(190deg);\n}\n\n.skewed {\n\t@extend %boy;\n\ttransform: skew(10deg, 50deg);\n}\n\n.fucked {\n\t@extend %boy;\n\ttransform: scale(2, 2) skew(-20deg, -10deg) rotate(-20deg);\n\tperspective: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fucked": `jp4gx4kOu7O8brvfGkkm`,
	"skewed": `Fw4rVY2ZMhH2dOz27QLM`,
	"rotato": `cFntQLXtO8JagkN4pYO0`
};
export default ___CSS_LOADER_EXPORT___;
