// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lNES4S7TvAvjFByIpAjw {
  margin-block-end: 0;
  display: inline-block;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/TransFlag/style.scss"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,qBAAA;EACA,sBAAA;AADD","sourcesContent":["@import \"css/config.scss\";\n\n.main {\n\tmargin-block-end: 0;\n\tdisplay: inline-block;\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `lNES4S7TvAvjFByIpAjw`
};
export default ___CSS_LOADER_EXPORT___;
