// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KEvENS982J6u8DaJux7k {
  position: relative;
  margin-block-end: 2.25em;
  height: 100%;
}

.G_qecee1J_m_WlpybTME {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  margin-block-end: 0;
}

.xPDwTOe_NOL0dNqS6_LS {
  background: rgb(9, 24, 42);
  background: linear-gradient(0deg, rgba(34, 24, 28, 0.9) 0%, rgba(34, 24, 28, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
}

.TKJ5UeQkz2C0m_oTbtvk {
  width: 100%;
  padding: 0.5em;
  height: 30%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.XZI1HqFhPFdLAM9rDk5f {
  font-size: 1.5em;
  align-self: flex-end;
  margin-block-end: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CaptionedImage.scss","webpack://./src/css/config.scss"],"names":[],"mappings":"AAEA;EACC,kBAAA;EACA,wBCSkB;EDRlB,YAAA;AADD;;AAIA;EACC,WAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AADD;;AAIA;EAEC,0BAAA;EACA,mHAAA;AAFD;;AAKA;EACC,WAAA;EACA,cAAA;EACA,WAAA;EACA,aAAA;EAGA,kBAAA;EACA,SAAA;EACA,OAAA;AAJD;;AAOA;EACC,gBAAA;EACA,oBAAA;EACA,mBAAA;AAJD","sourcesContent":["@import \"css/config.scss\";\n\n.container {\n\tposition: relative;\n\tmargin-block-end: $body-line-height;\n\theight: 100%;\n}\n\n.image {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: block;\n\tobject-fit: cover;\n\tmargin-block-end: 0;\n}\n\n.gradient {\n\t// Generated with https://cssgradient.io/\n\tbackground: rgb(9,24,42);\n\tbackground: linear-gradient(0deg, rgba($background, 0.9) 0%, rgba($background, 0.6) 50%, rgba(#fff, 0) 100%);\n}\n\n.captionContainer {\n\twidth: 100%;\n\tpadding: 0.5em;\n\theight: 30%;\n\tdisplay: flex;\n\n\t// Mount to the bottom of the container\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 0;\n}\n\n.caption {\n\tfont-size: $body-font-size * 1.2;\n\talign-self: flex-end;\n\tmargin-block-end: 0;\n}\n","// Brand colors\n$text: #DAD2D5;\n$text-faded: #94878C;\n$background: #22181C;\n$accent-color: #FF3685;\n$blueb: #6093F3;\n$lello: #FFD254;\n$greeb: #7ACE5C;\n\n// Bulma overrides\n$family-sans-serif: Poppins, sans-serif;\n\n$body-font-size: 1.25em;\n$body-line-height: $body-font-size * 1.8;\n\n$primary: $accent-color;\n$body-background-color: $background;\n$strong-color: $accent-color;\n$link: $blueb;\n$link-hover: scale-color($blueb, $saturation: -30%);\n\n// Forces the max width on these display types\n$desktop: 960px;\n$widescreen: 960px;\n$fullhd: 960px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `KEvENS982J6u8DaJux7k`,
	"image": `G_qecee1J_m_WlpybTME`,
	"gradient": `xPDwTOe_NOL0dNqS6_LS`,
	"captionContainer": `TKJ5UeQkz2C0m_oTbtvk`,
	"caption": `XZI1HqFhPFdLAM9rDk5f`
};
export default ___CSS_LOADER_EXPORT___;
